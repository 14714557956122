

import { Box } from "@swan-io/lake/src/components/Box";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { CountryPicker } from "@swan-io/shared-business/src/components/CountryPicker";
import { PlacekitAddressSearchInput } from "@swan-io/shared-business/src/components/PlacekitAddressSearchInput";
import { TaxIdentificationNumberInput } from "@swan-io/shared-business/src/components/TaxIdentificationNumberInput";
import { CountryCCA3, allCountries } from "@swan-io/shared-business/src/constants/countries";
import { validateIndividualTaxNumber } from "@swan-io/shared-business/src/utils/validation";
import { combineValidators, useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useState } from "react";
import { Rifm } from "rifm";
import { P, match } from "ts-pattern";
import {
  AccountMembershipFragment,
} from "../graphql/partner";
import { locale, rifmDateProps, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import {
  validateAddressLine,
  validateBirthdate,
  validateName,
  validateRequired,
} from "../utils/validations";
import { MembershipCancelConfirmationModal } from "./MembershipCancelConfirmationModal";



type AllowedStatuses =
  | "AccountMembershipConsentPendingStatusInfo"
  | "AccountMembershipDisabledStatusInfo"
  | "AccountMembershipEnabledStatusInfo"
  | "AccountMembershipInvitationSentStatusInfo"
  | "AccountMembershipSuspendedStatusInfo"
  | "AccountMembershipBindingUserErrorStatusInfo";

type Props = {
  accountCountry: CountryCCA3;
  editingAccountMembershipId: string;
  editingAccountMembership: AccountMembershipFragment & {
    statusInfo: {
      __typename: AllowedStatuses;
    };
  };
  currentUserAccountMembershipId: string;
  currentUserAccountMembership: AccountMembershipFragment;
  onRefreshRequest: () => void;
  large: boolean;
};

export const MembershipDetailEditor = ({
  editingAccountMembership,
  editingAccountMembershipId,
  currentUserAccountMembershipId,
  accountCountry,
  onRefreshRequest,
}: Props) => {
  const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState(false);



  const { Field, FieldsListener, setFieldValue } = useForm({
    email: {
      initialValue: editingAccountMembership.email,
      validate: validateRequired,
    },
    lastName: {
      initialValue: match(editingAccountMembership)
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipInvitationSentStatusInfo",
                "AccountMembershipBindingUserErrorStatusInfo",
              ),
            },
          },
          accountMembership => accountMembership.statusInfo.restrictedTo.lastName,
        )
        .with({ user: { lastName: P.string } }, ({ user }) => user.lastName)
        .otherwise(() => ""),
      validate: validateName,
    },
    firstName: {
      initialValue: match(editingAccountMembership)
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipInvitationSentStatusInfo",
                "AccountMembershipBindingUserErrorStatusInfo",
              ),
            },
          },
          accountMembership => accountMembership.statusInfo.restrictedTo.firstName,
        )
        .with({ user: { firstName: P.string } }, ({ user }) => user.firstName)
        .otherwise(() => ""),
      validate: validateName,
    },
    birthDate: {
      initialValue: match(editingAccountMembership)
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipInvitationSentStatusInfo",
                "AccountMembershipBindingUserErrorStatusInfo",
              ),
            },
          },
          accountMembership =>
            dayjs(accountMembership.statusInfo.restrictedTo.birthDate).format(locale.dateFormat),
        )
        .with({ user: { birthDate: P.string } }, ({ user }) =>
          dayjs(user.birthDate).format(locale.dateFormat),
        )
        .otherwise(() => ""),
      validate: validateBirthdate,
    },
    phoneNumber: {
      initialValue: match(editingAccountMembership)
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipInvitationSentStatusInfo",
                "AccountMembershipBindingUserErrorStatusInfo",
              ),
            },
          },
          accountMembership => accountMembership.statusInfo.restrictedTo.phoneNumber,
        )
        .with({ user: { mobilePhoneNumber: P.string } }, ({ user }) => user.mobilePhoneNumber)
        .otherwise(() => ""),
      validate: validateRequired,
    },
    // German account specific fields
    addressLine1: {
      initialValue: editingAccountMembership.residencyAddress?.addressLine1 ?? "",
      validate: combineValidators(validateRequired, validateAddressLine),
    },
    postalCode: {
      initialValue: editingAccountMembership.residencyAddress?.postalCode ?? "",
      validate: validateRequired,
    },
    city: {
      initialValue: editingAccountMembership.residencyAddress?.city ?? "",
      validate: validateRequired,
    },
    country: {
      initialValue:
        (editingAccountMembership.residencyAddress?.country as CountryCCA3 | null | undefined) ??
        accountCountry ??
        "FRA",
      validate: validateRequired,
    },
    taxIdentificationNumber: {
      initialValue: editingAccountMembership.taxIdentificationNumber ?? "",
      strategy: "onBlur",
      validate: (value, { getFieldValue }) => {
        return match({
          accountCountry,
          country: getFieldValue("country"),
          canViewAccount: editingAccountMembership.canViewAccount,
          canInitiatePayment: editingAccountMembership.canInitiatePayments,
        })
          .with(
            P.intersection(
              { accountCountry: "DEU", country: "DEU" },
              P.union(
                {
                  canViewAccount: true,
                },
                { canInitiatePayment: true },
              ),
            ),
            ({ accountCountry }) =>
              combineValidators(
                validateRequired,
                validateIndividualTaxNumber(accountCountry),
              )(value),
          )
          .with({ accountCountry: "DEU" }, ({ accountCountry }) =>
            validateIndividualTaxNumber(accountCountry)(value),
          )
          .otherwise(() => {});
      },
      sanitize: value => value.trim(),
    },
  });

  return (
    <>
      <Space height={24} />

      {match(editingAccountMembership)
        // Personal information are not editable while active
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipEnabledStatusInfo",
                "AccountMembershipSuspendedStatusInfo",
              ),
            },
            user: P.nonNullable,
          },
          accountMembership => (
            <>
              <LakeLabel
                label={t("membershipDetail.edit.birthDate")}
                render={id => (
                  <Rifm
                    value={
                      accountMembership.user.birthDate != null
                        ? dayjs(accountMembership.user.birthDate).format(locale.dateFormat)
                        : ""
                    }
                    onChange={() => {}}
                    {...rifmDateProps}
                  >
                    {({ value }) => <LakeTextInput id={id} readOnly={true} value={value} />}
                  </Rifm>
                )}
              />

              <LakeLabel
                label={t("membershipDetail.edit.phoneNumber")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    readOnly={true}
                    placeholder="+33600000000"
                    value={accountMembership.user.mobilePhoneNumber ?? ""}
                  />
                )}
              />

              <Field name="email">
                {({ value, valid, error, ref }) => (
                  <LakeLabel
                    label={t("membershipDetail.edit.email")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={value}
                        valid={valid}
                        error={error}
                        // `email` is editable when enabled
                        readOnly={true}
                      />
                    )}
                  />
                )}
              </Field>
            </>
          ),
        )
        .with(
          {
            statusInfo: {
              __typename: P.union(
                "AccountMembershipInvitationSentStatusInfo",
                "AccountMembershipBindingUserErrorStatusInfo",
              ),
            },
          },
          () => (
            <>
              <Field name="email">
                {({ value, valid, error, ref }) => (
                  <LakeLabel
                    label={t("membershipDetail.edit.email")}
                    render={id => (
                      <Box direction="row">
                        <LakeTextInput
                          id={id}
                          ref={ref}
                          value={value}
                          valid={valid}
                          error={error}
                          readOnly={true}
                          
                        />


                      </Box>
                    )}
                  />
                )}
              </Field>

              <Field name="lastName">
                {({ value, valid, error, ref }) => (
                  <LakeLabel
                    label={t("membershipDetail.edit.lastName")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={value}
                        valid={valid}
                        error={error}
                        readOnly={true}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="firstName">
                {({ value, valid, error, ref }) => (
                  <LakeLabel
                    label={t("membershipDetail.edit.firstName")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        value={value}
                        valid={valid}
                        error={error}
                        readOnly={true}
                      />
                    )}
                  />
                )}
              </Field>

              <Field name="birthDate">
                {({ value, valid, error, onChange, ref }) => (
                  <Rifm value={value ?? ""} onChange={onChange} {...rifmDateProps}>
                    {({ value }) => (
                      <LakeLabel
                        label={t("membershipDetail.edit.birthDate")}
                        render={id => (
                          <LakeTextInput
                            id={id}
                            ref={ref}
                            placeholder={locale.datePlaceholder}
                            value={value ?? ""}
                            valid={valid}
                            error={error}
                            readOnly={true}
                          />
                        )}
                      />
                    )}
                  </Rifm>
                )}
              </Field>

              <Field name="phoneNumber">
                {({ value, valid, error, ref }) => (
                  <LakeLabel
                    label={t("membershipDetail.edit.phoneNumber")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        ref={ref}
                        placeholder="+33600000000"
                        value={value ?? ""}
                        valid={valid}
                        error={error}
                        readOnly={true}
                        inputMode="tel"
                      />
                    )}
                  />
                )}
              </Field>
            </>
          ),
        )
        .otherwise(() => null)}

      {match({ accountCountry, editingAccountMembership })
        .with({ accountCountry: "DEU" }, { accountCountry: "NLD" }, () => (
          <>
            <Field name="country">
              {({ value, error, onChange, ref }) => (
                <LakeLabel
                  label={t("membershipDetail.edit.country")}
                  render={id => (
                    <CountryPicker
                      id={id}
                      ref={ref}
                      countries={allCountries}
                      value={value}
                      onValueChange={onChange}
                      error={error}
                    />
                  )}
                />
              )}
            </Field>

            <FieldsListener names={["country"]}>
              {({ country }) => {
                return (
                  <Field name="addressLine1">
                    {({ value, onChange, error }) => (
                      <LakeLabel
                        label={t("cardWizard.address.line1")}
                        render={id => (
                          <PlacekitAddressSearchInput
                            apiKey={__env.CLIENT_PLACEKIT_API_KEY}
                            country={country.value}
                            value={value}
                            onValueChange={onChange}
                            onSuggestion={suggestion => {
                              setFieldValue("addressLine1", suggestion.completeAddress);
                              setFieldValue("city", suggestion.city);
                              setFieldValue("postalCode", suggestion.postalCode ?? "");
                            }}
                            language={locale.language}
                            placeholder={t("addressInput.placeholder")}
                            emptyResultText={t("common.noResults")}
                            error={error}
                            id={id}
                          />
                        )}
                      />
                    )}
                  </Field>
                );
              }}
            </FieldsListener>

            <Field name="postalCode">
              {({ value, valid, error, onChange, ref }) => (
                <LakeLabel
                  label={t("membershipDetail.edit.postalCode")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      ref={ref}
                      value={value}
                      valid={valid}
                      error={error}
                      onChangeText={onChange}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="city">
              {({ value, valid, error, onChange, ref }) => (
                <LakeLabel
                  label={t("membershipDetail.edit.city")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      ref={ref}
                      value={value}
                      valid={valid}
                      error={error}
                      onChangeText={onChange}
                    />
                  )}
                />
              )}
            </Field>

            <FieldsListener names={["country"]}>
              {({ country }) =>
                match({ accountCountry, country: country.value })
                  .with(
                    { accountCountry: "DEU", country: "DEU" },
                    ({ accountCountry, country }) => (
                      <Field name="taxIdentificationNumber">
                        {({ value, valid, error, onChange, ref }) => (
                          <TaxIdentificationNumberInput
                            ref={ref}
                            accountCountry={accountCountry}
                            isCompany={false}
                            value={value}
                            valid={valid}
                            error={error}
                            onChange={onChange}
                            required={match({
                              accountCountry,
                              country,
                              canViewAccount: editingAccountMembership.canViewAccount,
                              canInitiatePayment: editingAccountMembership.canInitiatePayments,
                            })
                              .with(
                                P.intersection(
                                  { accountCountry: "DEU", country: "DEU" },
                                  P.union(
                                    {
                                      canViewAccount: true,
                                    },
                                    { canInitiatePayment: true },
                                  ),
                                ),
                                () => true,
                              )
                              .otherwise(() => false)}
                          />
                        )}
                      </Field>
                    ),
                  )
                  .otherwise(() => null)
              }
            </FieldsListener>
          </>
        ))
        .otherwise(() => null)}

      <MembershipCancelConfirmationModal
        visible={isCancelConfirmationModalOpen}
        onPressClose={() => setIsCancelConfirmationModalOpen(false)}
        accountMembershipId={editingAccountMembershipId}
        onSuccess={() => {
          Router.push("AccountMembersList", {
            accountMembershipId: currentUserAccountMembershipId,
          });
          onRefreshRequest();
        }}
      />

    </>
  );
};
